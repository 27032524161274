
import {Options, prop} from "vue-class-component";
import Vue from "../../extensions/Vue";
import ContractData from "../../http/data/contract-data";
import {contractContext} from "@/store";

class Props {
    contract = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof ContractData
    });
}

@Options({
    name: "ContractButton",
})
export default class ContractButton extends Vue.with(Props) {
    declare contract: ContractData;

    get contractLifeCycleStage(): string {
        return "stage__" + contractContext.getters.getContractLifeCycleStage(this.contract);
    }
}
