
import {Options, prop} from "vue-class-component";
import Vue from "@/extensions/Vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import JobButton from "@/components/controls/JobButton.vue";
import JobData from "@/http/data/job-data";
import {jobContext} from "@/store";
import _ from "lodash";
import {ElCollapse} from "element-plus";

class Props {
    jobs = prop({
        required: true,
        type: Array
    });
    mode = prop({
        required: false,
        type: String,
        default: "type"
    });
}

@Options({
    name: "JobAccordion",
    components: {
        JobButton,
        DefaultLayout
    },
})
export default class JobAccordion extends Vue.with(Props) {
    declare jobs: JobData[];
    declare mode: string;

    mounted(): void {
        this.$events.on("update", () => this.onUpdate());

        this.onUpdate();
    }

    get collapsedJobAccordionItem(): string {
        return jobContext.state.collapsedJobAccordionItem;
    }

    set collapsedJobAccordionItem(value: string) {
        jobContext.mutations.setCollapsedJobAccordionItem(value);
    }

    get jobsByType(): Record<string, JobData[]> {
        return _.chain(this.jobs)
            .orderBy(job => [job.type, job.perform_date, job.unit_id])
            .groupBy(job => job.type)
            .value();
    }

    onChange(collapsedJobAccordionItem: string): void {
        this.collapsedJobAccordionItem = collapsedJobAccordionItem;
    }

    onUpdate(): void {
        const jobsAccordion = this.$refs["jobsAccordion"] as typeof ElCollapse;

        if (!jobsAccordion) return;

        jobsAccordion.setActiveNames(this.collapsedJobAccordionItem);
    }
}
