<template>
    <div class="row">
        <section class="col-12 col-sm-6">
            <div class="text-primary fw-bold">Contract</div>
            <div>
                <span>Total balance:&nbsp;</span>
                <span class="fw-bold">{{ formatCurrency(contractInfo.financial.accrued_debt_total) }}</span>
            </div>
            <div>
                <span>Total balance today:&nbsp;</span>
                <span class="fw-bold">{{ formatCurrency(contractInfo.financial.accrued_debt_today) }}</span>
            </div>
            <div>
                <span>Total paid:&nbsp;</span>
                <span class="fw-bold">{{ formatCurrency(contractInfo.financial.paid_total) }}</span>
            </div>
            <div>
                <span>Total to pay:&nbsp;</span>
                <span class="fw-bold">{{ formatCurrency(contractInfo.financial.to_pay_total) }}</span>
            </div>
            <div>
                <span>Total to pay today:&nbsp;</span>
                <span class="fw-bold" :class="determineToPayTodayTextColor(contractInfo.financial.to_pay_today)">
                    {{ formatCurrency(contractInfo.financial.to_pay_today) }}
                </span>
            </div>
        </section>
        <section class="col-12 col-sm-6 mt-3 mt-sm-0">
            <div class="text-primary fw-bold">Reservation</div>
            <div>
                <span>Total balance:&nbsp;</span>
                <span class="fw-bold">{{ formatCurrency(contractInfo.reservation.financial.accrued_debt_total) }}</span>
            </div>
            <div>
                <span>Total balance today:&nbsp;</span>
                <span class="fw-bold">{{ formatCurrency(contractInfo.reservation.financial.accrued_debt_today) }}</span>
            </div>
            <div>
                <span>Total paid:&nbsp;</span>
                <span class="fw-bold">{{ formatCurrency(contractInfo.reservation.financial.paid_total) }}</span>
            </div>
            <div>
                <span>Total to pay:&nbsp;</span>
                <span class="fw-bold">{{ formatCurrency(contractInfo.reservation.financial.to_pay_total) }}</span>
            </div>
            <div>
                <span>Total to pay today:&nbsp;</span>
                <span class="fw-bold" :class="determineToPayTodayTextColor(contractInfo.reservation.financial.to_pay_today)">
                    {{ formatCurrency(contractInfo.reservation.financial.to_pay_today) }}
                </span>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import ContractInfoData from "../../../http/data/contract-info-data";

class Props {
    contractInfo = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof ContractInfoData
    });
}

@Options({
    name: "ContractInfoFinancial"
})
export default class ContractInfoFinancial extends Vue.with(Props) {
    declare contractInfo: ContractInfoData;

    determineToPayTodayTextColor(value: number): string {
        if (value > 0) return "text-danger";
        else return "text-success";
    }
}
</script>

<style scoped>

</style>