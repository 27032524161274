<template>
    <default-layout>
        <div class="mt-3 mb-2">
            <h1 class="text-center mb-0">{{ contractInfoId }}</h1>
            <div class="mx-1" v-if="contractInfo">
                <div v-if="contractInfo.unit.id">
                    <h2 class="text-center text-info mb-0 mt-2">{{ contractInfo.unit.id }}</h2>
                    <div class="text-center font-small-1 fst-italic">{{ contractInfo.unit.description }}</div>
                </div>
                <div class="mt-3">
                    <el-tabs v-model="activeTab">
                        <el-tab-pane label="Summary" name="SummaryTab">
                            <contract-info-summary class="mx-4 mx-sm-5" :contract-info="contractInfo" />
                        </el-tab-pane>
                        <el-tab-pane class="mx-4" label="Profile" name="ProfileTab">
                            <contract-info-profile :contract-info="contractInfo" />
                        </el-tab-pane>
                        <el-tab-pane label="Financial" name="FinancialTab">
                            <contract-info-financial class="mx-4" :contract-info="contractInfo" />
                        </el-tab-pane>
                        <el-tab-pane label="Options" name="OptionsTab">
                            <contract-info-options :contract-info="contractInfo" />
                        </el-tab-pane>
                        <el-tab-pane label="Notes" name="NotesTab">
                            <contract-info-notes class="mx-1" :contract-info="contractInfo" />
                        </el-tab-pane>
                        <el-tab-pane label="Jobs" name="JobsTab">
                            <contract-info-jobs :contract-info="contractInfo" />
                        </el-tab-pane>
                        <el-tab-pane label="Siblings" name="SiblingsTab">
                            <contract-info-siblings :contract-info="contractInfo" />
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            <div v-else>
                <div v-loading="true" class="text-center mt-5"></div>
            </div>
        </div>
    </default-layout>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import ContractInfoData from "@/http/data/contract-info-data";
import {contractContext} from "@/store";
import ContractInfoSummary from "@/components/ui/ContractInfo/ContractInfoSummary.vue";
import ContractInfoProfile from "@/components/ui/ContractInfo/ContractInfoProfile.vue";
import ContractInfoFinancial from "@/components/ui/ContractInfo/ContractInfoFinancial.vue";
import ContractInfoJobs from "@/components/ui/ContractInfo/ContractInfoJobs.vue";
import ContractInfoNotes from "@/components/ui/ContractInfo/ContractInfoNotes.vue";
import ContractInfoOptions from "@/components/ui/ContractInfo/ContractInfoOptions.vue";
import ContractInfoSiblings from "@/components/ui/ContractInfo/ContractInfoSiblings.vue";

@Options({
    name: "ContractSearch",
    components: {
        ContractInfoSiblings,
        ContractInfoOptions,
        ContractInfoNotes,
        ContractInfoJobs,
        ContractInfoFinancial,
        ContractInfoProfile,
        ContractInfoSummary,
        DefaultLayout,
    }
})
export default class ContractSearch extends Vue {
    activeTab = "SummaryTab";

    fetchingContract = false;

    beforeMount(): void {
        this.$events.on("update", () => this.onUpdate());

        this.onUpdate();
    }

    get contractInfo(): ContractInfoData|null {
        return contractContext.state.selectedContractInfo;
    }

    get contractInfoId(): string {
        return contractContext.state.selectedContractInfoId as string;
    }

    async onUpdate(): Promise<void> {
        if (
            !contractContext.state.isInitialized
            || contractContext.state.selectedContractInfo
            || this.fetchingContract
        ) {
            return;
        }

        this.fetchingContract = true;

        try {
            await contractContext.actions.fetchContractInfo();
        } finally {
            this.fetchingContract = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

h2 {
    line-height: .75em;
}
</style>