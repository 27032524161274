<template>
    <div class="contract-info__summary">
        <div class="d-flex gap-x-3">
            <div>
                <i class="fa fa-user-plus me-1" />
                <span>{{ contractInfo.guest_amount }}</span>
            </div>
            <div>
                <i class="fa fa-moon me-1" />
                <span>{{ nights }}</span>
            </div>
        </div>
        <div>
            <i class="fa fa-suitcase-rolling me-1" />
            <span>{{ formatDate(contractInfo.start_date) }}</span>
        </div>
        <div>
            <i class="fa fa-shoe-prints me-1" />
            <span>{{ formatDate(contractInfo.end_date) }}</span>
        </div>
        <div>
            <i class="fa fa-building me-1" />
            <span>{{ contractInfo.reservation.building.name }}</span>
        </div>
        <div>
            <i class="fa fa-bed me-1" />
            <span>{{ `${contractInfo.unit.description} (${contractInfo.unit.type_id})` }}</span>
        </div>
        <div>
            <i class="fa fa-file-import me-1" />
            <span>{{ `${capitalize(contractInfo.source.description)} (${contractInfo.reservation.id})` }}</span>
        </div>
        <div>
            <i class="fa fa-cash-register me-1" />
            <span>{{ paymentMethod.description }}&nbsp;</span>
            <span>{{ `(${paymentMethod.is_online_payment ? capitalize(paymentMethod.id) : paymentMethod.id})` }}</span>
        </div>
        <div v-if="unit.has_inactive_device">
            <div style="display: inline-block; position: relative; margin-right: 5px;">
                <i class="fa fa-rss"></i>
                <i class="fa fa-exclamation-triangle fa-xs" style="position: absolute;  top: -20%; right: -50%;"></i>
            </div>
            <span>Device unreachable</span>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import dayjs from "dayjs";
import ContractInfoData, {PaymentMethod} from "../../../http/data/contract-info-data";
import {rootContext} from "@/store";
import UnitData from "@/http/data/unit-data";

export const unitContext = rootContext.modules.unit;

class Props {
    contractInfo = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof ContractInfoData
    });
}

@Options({
    name: "ContractInfoSummary"
})
export default class ContractInfoSummary extends Vue.with(Props) {
    declare contractInfo: ContractInfoData;

    get unit(): UnitData {
        return unitContext.getters.selectedUnit as UnitData;
    }

    get paymentMethod(): PaymentMethod {
        return this.contractInfo.payment_method;
    }

    get nights(): number {
        return dayjs(this.contractInfo.end_date).diff(this.contractInfo.start_date, "day");
    }
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.contract-info__summary {
    >div {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        &:not(:last-child) {
            border-bottom: 1px dashed black;
        }
    }
}

i {
    &.fa {
        width: 20px;
    }
    &.fa-building {
        padding-left: 3px;
    }
    &.fa-wallet {
        padding-left: 2px;
    }
    &.fa-suitcase-rolling {
        padding-left: 2px;
    }
}
</style>