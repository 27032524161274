
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import ContractInfoData from "../../../http/data/contract-info-data";
import ContractClientData from "@/http/data/contract-client-data";
import ContractRepresentativeData from "@/http/data/contract-representative-data";
import ContractGuestData from "@/http/data/contract-guest-data";

class Props {
    contractInfo = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof ContractInfoData
    });
}

@Options({
    name: "ContractInfoProfile"
})
export default class ContractInfoProfile extends Vue.with(Props) {
    declare contractInfo: ContractInfoData;

    get client(): ContractClientData {
        return this.contractInfo.client;
    }

    get clientTypeIcon(): string {
        return this.isBusiness ? "fa-building" : "fa-user";
    }

    get guest(): ContractGuestData {
        return this.contractInfo.guest;
    }

    get isBusiness(): boolean {
        return this.client.type === "B";
    }

    get representative(): ContractRepresentativeData {
        return this.contractInfo.representative;
    }
    
    convertLanguageCodeToFullText(code: string): string {
        switch (code.toLowerCase()) {
        case "fr": return "Frans";
        case "nl": return "Nederlands";
        default: return "Engels";
        }
    }
}
