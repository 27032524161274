
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import ContractInfoData from "@/http/data/contract-info-data";
import {contractContext} from "@/store";
import ContractInfoSummary from "@/components/ui/ContractInfo/ContractInfoSummary.vue";
import ContractInfoProfile from "@/components/ui/ContractInfo/ContractInfoProfile.vue";
import ContractInfoFinancial from "@/components/ui/ContractInfo/ContractInfoFinancial.vue";
import ContractInfoJobs from "@/components/ui/ContractInfo/ContractInfoJobs.vue";
import ContractInfoNotes from "@/components/ui/ContractInfo/ContractInfoNotes.vue";
import ContractInfoOptions from "@/components/ui/ContractInfo/ContractInfoOptions.vue";
import ContractInfoSiblings from "@/components/ui/ContractInfo/ContractInfoSiblings.vue";

@Options({
    name: "ContractSearch",
    components: {
        ContractInfoSiblings,
        ContractInfoOptions,
        ContractInfoNotes,
        ContractInfoJobs,
        ContractInfoFinancial,
        ContractInfoProfile,
        ContractInfoSummary,
        DefaultLayout,
    }
})
export default class ContractSearch extends Vue {
    activeTab = "SummaryTab";

    fetchingContract = false;

    beforeMount(): void {
        this.$events.on("update", () => this.onUpdate());

        this.onUpdate();
    }

    get contractInfo(): ContractInfoData|null {
        return contractContext.state.selectedContractInfo;
    }

    get contractInfoId(): string {
        return contractContext.state.selectedContractInfoId as string;
    }

    async onUpdate(): Promise<void> {
        if (
            !contractContext.state.isInitialized
            || contractContext.state.selectedContractInfo
            || this.fetchingContract
        ) {
            return;
        }

        this.fetchingContract = true;

        try {
            await contractContext.actions.fetchContractInfo();
        } finally {
            this.fetchingContract = false;
        }
    }
}
