
import {Options, prop} from "vue-class-component";
import Vue from "@/extensions/Vue";
import InvoiceLine from "@/components/ui/InvoiceLine.vue";
import InvoiceLineData from "@/http/data/invoice-line-data";
import _ from "lodash";
import {ElCollapse} from "element-plus";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

class Props {
    invoiceLines = prop({
        required: true,
        validator: (value: unknown): boolean => Array.isArray(value)
            && _.every(value, item => item instanceof InvoiceLineData)
    });
}

@Options({
    name: "InvoiceLineCollapsable",
    components: {
        InvoiceLine,
    },
})
export default class InvoiceLineCollapsable extends Vue.with(Props) {
    declare invoiceLines: InvoiceLineData[];

    activeItems: string[] = [];

    firstInit = false;

    mounted(): void {
        this.$events.on("update", () => this.onUpdate());

        this.onUpdate();
    }

    get invoiceLinesByPeriod(): Record<string, InvoiceLineData[]> {
        return _.chain(this.invoiceLines)
            .orderBy([
                (invoiceLine: InvoiceLineData) => invoiceLine.start_date,
                (invoiceLine: InvoiceLineData) => invoiceLine.end_date
            ], ["desc", "desc"])
            .groupBy((invoiceLine: InvoiceLineData) => {
                const format = "DD/MM/YYYY";

                const startDate = dayjs(invoiceLine.start_date).format(format);
                const endDate = dayjs(invoiceLine.end_date).format(format);

                return `${startDate} - ${endDate}`;
            })
            .value();
    }

    initialActiveItems(): string[] {
        return _.chain(this.invoiceLinesByPeriod)
            .pickBy((invoiceLine: InvoiceLineData[]) => {
                return dayjs().isBetween(invoiceLine[0].start_date, invoiceLine[0].end_date, "day", "[]");
            })
            .keys()
            .value();
    }

    onUpdate(): void {
        const invoiceLineCollapsible = this.$refs["invoiceLineCollapsible"] as typeof ElCollapse;

        if (!invoiceLineCollapsible) return;

        if(!this.firstInit) {
            this.activeItems = this.initialActiveItems();
            this.firstInit = true;
        }

        invoiceLineCollapsible.setActiveNames(this.activeItems);
    }
}
