
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import ContractInfoData from "../../../http/data/contract-info-data";

class Props {
    contractInfo = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof ContractInfoData
    });
}

@Options({
    name: "ContractInfoFinancial"
})
export default class ContractInfoFinancial extends Vue.with(Props) {
    declare contractInfo: ContractInfoData;

    determineToPayTodayTextColor(value: number): string {
        if (value > 0) return "text-danger";
        else return "text-success";
    }
}
