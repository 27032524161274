<template>
    <div class="row">
        <section class="col-12 col-sm-6">
            <div class="text-primary fw-bold">Invoice address</div>
            <div>
                <i class="fa me-1" :class="clientTypeIcon"/>
                <span v-if="isBusiness">{{ `${client.company_name} ${client.company_type}` }}</span>
                <span v-else>{{ capitalizeAll(`${client.first_name} ${client.last_name}`) }}</span>
            </div>
            <div v-if="isBusiness && client.company_vat"><span class="fw-bold">VAT</span> {{ client.company_vat }}</div>
            <div class="d-flex">
                <i class="fa fa-map-marker-alt mt-1 me-2"/>
                <div>
                    <div>{{ `${capitalize(client.address)}` }}</div>
                    <div>{{ `${client.zipcode} ${capitalize(client.city)}` }}</div>
                    <div>{{ `${client.country_name ?? client.country}` }}</div>
                </div>
            </div>
            <div class="text-primary fw-bold mt-3">Contact</div>
            <div class="text-nowrap">
                <i class="fa fa-envelope me-1"/>
                <span class="overflow-break">{{ representative.email }}</span>
            </div>
            <div><i class="fa fa-phone me-1"/>{{ representative.phone_number }}</div>
            <div><i class="fa fa-globe me-1"/>{{ convertLanguageCodeToFullText(representative.language) }}</div>
        </section>
        <section class="col-12 col-sm-6 mt-3 mt-sm-0">
            <div class="text-primary fw-bold">Guest</div>
            <div><i class="fa fa-id-card me-1"/>{{ capitalizeAll(`${guest.first_name} ${guest.last_name}`) }}</div>
            <div class="text-nowrap">
                <i class="fa fa-envelope me-1"/>
                <span class="overflow-break">{{ guest.email }}</span>
            </div>
            <div><i class="fa fa-phone me-1"/>{{ guest.phone_number }}</div>
            <div><i class="fa fa-globe me-1"/>{{ convertLanguageCodeToFullText(guest.language) }}</div>
        </section>
    </div>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import ContractInfoData from "../../../http/data/contract-info-data";
import ContractClientData from "@/http/data/contract-client-data";
import ContractRepresentativeData from "@/http/data/contract-representative-data";
import ContractGuestData from "@/http/data/contract-guest-data";

class Props {
    contractInfo = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof ContractInfoData
    });
}

@Options({
    name: "ContractInfoProfile"
})
export default class ContractInfoProfile extends Vue.with(Props) {
    declare contractInfo: ContractInfoData;

    get client(): ContractClientData {
        return this.contractInfo.client;
    }

    get clientTypeIcon(): string {
        return this.isBusiness ? "fa-building" : "fa-user";
    }

    get guest(): ContractGuestData {
        return this.contractInfo.guest;
    }

    get isBusiness(): boolean {
        return this.client.type === "B";
    }

    get representative(): ContractRepresentativeData {
        return this.contractInfo.representative;
    }
    
    convertLanguageCodeToFullText(code: string): string {
        switch (code.toLowerCase()) {
        case "fr": return "Frans";
        case "nl": return "Nederlands";
        default: return "Engels";
        }
    }
}
</script>

<style scoped>
.overflow-break {
    white-space: normal;
    overflow-wrap: break-word;
}
</style>