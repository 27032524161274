
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import ContractInfoData from "../../../http/data/contract-info-data";
import JobAccordion from "@/components/controls/JobAccordion.vue";

class Props {
    contractInfo = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof ContractInfoData
    });
}

@Options({
    name: "ContractInfoJobs",
    components: {JobAccordion}
})
export default class ContractInfoJobs extends Vue.with(Props) {
    declare contractInfo: ContractInfoData;
}
