
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import ContractInfoData, {Sibling} from "../../../http/data/contract-info-data";
import ContractButton from "../../controls/ContractButton.vue";
import ContractSiblingButton from "@/components/controls/ContractSiblingButton.vue";
import {contractContext} from "@/store";

class Props {
    contractInfo = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof ContractInfoData
    });
}

@Options({
    name: "ContractInfoSiblings",
    components: {ContractSiblingButton, ContractButton}
})
export default class ContractInfoSiblings extends Vue.with(Props) {
    declare contractInfo: ContractInfoData;

    get siblings(): Sibling[] {
        return this.contractInfo.siblings;
    }

    async selectContractInfo(contractId: string): Promise<void> {
        await contractContext.actions.selectContractInfoId(contractId);
    }
}
