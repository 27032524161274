<template>
    <div class="w-100 row contract-button g-0">
        <div class="col-4">
            <div class="h-100 contract-header" :class="contractLifeCycleStage">
                <div class="fw-bold">{{ contract.id }}</div>
            </div>
        </div>
        <div class="col-8">
            <div class="contract-body d-flex justify-content-between flex-wrap" :class="contractLifeCycleStage">
                <div class="d-flex align-items-center">
                    <i class="fa fa-suitcase-rolling me-2" />
                    <span>{{ formatDate(contract.start_date) }}</span>
                </div>
                <div class="d-flex align-items-center">
                    <i class="fa fa-shoe-prints me-2" />
                    <span>{{ formatDate(contract.end_date) }}</span>
                </div>
                <div class="d-flex align-items-center">
                    <i class="fa fa-door-closed me-2" />
                    <span>{{ contract.unit_id ?? "N/A" }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {Options, prop} from "vue-class-component";
import Vue from "../../extensions/Vue";
import ContractData from "../../http/data/contract-data";
import {contractContext} from "@/store";

class Props {
    contract = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof ContractData
    });
}

@Options({
    name: "ContractButton",
})
export default class ContractButton extends Vue.with(Props) {
    declare contract: ContractData;

    get contractLifeCycleStage(): string {
        return "stage__" + contractContext.getters.getContractLifeCycleStage(this.contract);
    }
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.contract-body {
    column-gap: 1rem;
    padding: .5rem;

    &.stage {
        &__cancelled {
            background-color: $red-lighten-5;
        }
        &__unprocessed {
            background-color: $grey-lighten-5;
        }
        &__processed {
            background-color: $green-lighten-5;
        }
        &__arriving {
            background-color: $purple-lighten-5;
        }
        &__active {
            background-color: $blue-lighten-5;
        }
        &__departing {
            background-color: $yellow-lighten-5;
        }
        &__departed {
            background-color: $brown-lighten-5;
        }
        &__end-of-life {
            background-color: $blue-grey-lighten-3;
        }
    }
}

.contract-header {
    padding: .5rem;

    &.stage {
        &__cancelled {
            color: $red-darken-4;
            background-color: $red-lighten-4;
        }
        &__unprocessed {
            color: $grey-darken-4;
            background-color: $grey-lighten-3;
        }
        &__processed {
            color: $green-darken-4;
            background-color: $green-lighten-4;
        }
        &__arriving {
            color: $purple-darken-2;
            background-color: $purple-lighten-4;
        }
        &__active {
            color: $blue-darken-2;
            background-color: $blue-lighten-4;
        }
        &__departing {
            color: $yellow-darken-4;
            background-color: $yellow-lighten-4;
        }
        &__departed {
            color: $brown-darken-4;
            background-color: $brown-lighten-4;
        }
        &__end-of-life {
            color: $blue-grey-darken-4;
            background-color: $blue-grey-lighten-2;
        }
    }
}

.contract-button {
    cursor: pointer;

    &:hover {
        filter: contrast(70%);
    }
}

i {
    &.fa-suitcase-rolling {
        padding-left: 4px;
        padding-right: 4px;
    }
    &.fa-user {
        padding-left: 3px;
        padding-right: 3px;
    }
}
</style>