
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import InvoiceLineData from "@/http/data/invoice-line-data";

class Props {
    invoiceLine = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof InvoiceLineData
    });
}

@Options({
    name: "InvoiceLine"
})
export default class InvoiceLine extends Vue.with(Props) {
    declare invoiceLine: InvoiceLineData;
}
