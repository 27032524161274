<template>
    <div class="row mx-1">
        <template v-if="siblings.length > 0">
            <div class="col-12 col-sm-6 gy-2 gx-2"
                 v-for="sibling in siblings"
                 :key="sibling.id">
                <contract-sibling-button class="w-100 contract-sibling-btn"
                                         :sibling="sibling"
                                         @click="selectContractInfo(sibling.id)"/>
            </div>
        </template>
        <div class="text-center fw-bold" v-else>This contract has no siblings.</div>
    </div>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import ContractInfoData, {Sibling} from "../../../http/data/contract-info-data";
import ContractButton from "../../controls/ContractButton.vue";
import ContractSiblingButton from "@/components/controls/ContractSiblingButton.vue";
import {contractContext} from "@/store";

class Props {
    contractInfo = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof ContractInfoData
    });
}

@Options({
    name: "ContractInfoSiblings",
    components: {ContractSiblingButton, ContractButton}
})
export default class ContractInfoSiblings extends Vue.with(Props) {
    declare contractInfo: ContractInfoData;

    get siblings(): Sibling[] {
        return this.contractInfo.siblings;
    }

    async selectContractInfo(contractId: string): Promise<void> {
        await contractContext.actions.selectContractInfoId(contractId);
    }
}
</script>

<style scoped>
</style>