
import {Options, prop} from "vue-class-component";
import Vue from "../../extensions/Vue";
import {Sibling} from "@/http/data/contract-info-data";

class Props {
    sibling = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof Sibling
    });
}

@Options({
    name: "ContractSiblingButton",
})
export default class ContractSiblingButton extends Vue.with(Props) {
    declare sibling: Sibling;
}
