<template>
    <el-button>
        <div class="text-info fw-bold mb-1">{{sibling.id}}</div>
        <div class="mb-1">{{sibling.unit_type_name}}</div>
        <div class="fw-bold">
            <i class="fa fa-door-closed me-1"/>
            <span>{{sibling.unit_id}}</span>
        </div>
    </el-button>
</template>

<script lang="ts">
import {Options, prop} from "vue-class-component";
import Vue from "../../extensions/Vue";
import {Sibling} from "@/http/data/contract-info-data";

class Props {
    sibling = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof Sibling
    });
}

@Options({
    name: "ContractSiblingButton",
})
export default class ContractSiblingButton extends Vue.with(Props) {
    declare sibling: Sibling;
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

:hover {
    cursor: pointer;
    background-color: $grey-lighten-4;
}
</style>