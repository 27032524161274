
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import dayjs from "dayjs";
import ContractInfoData, {PaymentMethod} from "../../../http/data/contract-info-data";
import {rootContext} from "@/store";
import UnitData from "@/http/data/unit-data";

export const unitContext = rootContext.modules.unit;

class Props {
    contractInfo = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof ContractInfoData
    });
}

@Options({
    name: "ContractInfoSummary"
})
export default class ContractInfoSummary extends Vue.with(Props) {
    declare contractInfo: ContractInfoData;

    get unit(): UnitData {
        return unitContext.getters.selectedUnit as UnitData;
    }

    get paymentMethod(): PaymentMethod {
        return this.contractInfo.payment_method;
    }

    get nights(): number {
        return dayjs(this.contractInfo.end_date).diff(this.contractInfo.start_date, "day");
    }
}
