
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import ContractInfoData from "@/http/data/contract-info-data";
import ContractNote from "../ContractNote.vue";
import ContractNoteData from "@/http/data/contract-note-data";
import _ from "lodash";

class Props {
    contractInfo = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof ContractInfoData
    });
}

@Options({
    name: "ContractInfoNotes",
    components: {ContractNote}
})
export default class ContractInfoNotes extends Vue.with(Props) {
    declare contractInfo: ContractInfoData;

    get notes(): ContractNoteData[] {
        return _.orderBy(this.contractInfo.notes, (note: ContractNoteData) => note.created_at, ["desc"]);
    }
}
