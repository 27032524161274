
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import ContractNoteData from "@/http/data/contract-note-data";

class Props {
    contractNote = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof ContractNoteData
    });
}

@Options({
    name: "ContractNote"
})
export default class ContractNote extends Vue.with(Props) {
    declare contractNote: ContractNoteData;
}
