<template>
    <div class="contract-note">
        <div class="contract-note__description fw-bold" :class="contractNote.type_id">{{ contractNote.type_description }}</div>
        <div v-html="contractNote.description" />
        <div>
            <span class="fw-bold font-small-3">{{ formatDatetime(contractNote.created_at) }} &bullet; </span>
            <span class="fw-bold font-small-3">{{ contractNote.created_by }}</span>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import ContractNoteData from "@/http/data/contract-note-data";

class Props {
    contractNote = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof ContractNoteData
    });
}

@Options({
    name: "ContractNote"
})
export default class ContractNote extends Vue.with(Props) {
    declare contractNote: ContractNoteData;
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.contract-note__description {
    color: $primary;

    &.client_remark {
        color: $orange-darken-3;
    }

    &.concierge_remark {
        color: $indigo
    }

    &.sales_remark {
        color: $teal;
    }
}
</style>