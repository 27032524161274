<template>
    <div>
        <div class="d-flex justify-content-between">
            <div>
                <div class="text-primary fw-bold">{{ invoiceLine.description }}</div>
                <div class="font-small-2">{{ capitalize(invoiceLine.type_name) }}</div>
            </div>
            <div class="text-end">
                <div class="fw-bold">{{ formatCurrency(invoiceLine.price) }}</div>
                <div class="font-small-2" v-if="invoiceLine.vat_percentage > 0">
                    {{
                        `BTW ${formatCurrency(invoiceLine.vat_price)} (${invoiceLine.vat_percentage}%)`
                    }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import InvoiceLineData from "@/http/data/invoice-line-data";

class Props {
    invoiceLine = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof InvoiceLineData
    });
}

@Options({
    name: "InvoiceLine"
})
export default class InvoiceLine extends Vue.with(Props) {
    declare invoiceLine: InvoiceLineData;
}
</script>

<style scoped>

</style>