
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import ContractInfoData from "@/http/data/contract-info-data";
import InvoiceLineData from "@/http/data/invoice-line-data";
import InvoiceLineCollapsable from "@/components/controls/InvoiceLineCollapsable.vue";

class Props {
    contractInfo = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof ContractInfoData
    });
}

@Options({
    name: "ContractInfoOptions",
    components: {InvoiceLineCollapsable}
})
export default class ContractInfoOptions extends Vue.with(Props) {
    declare contractInfo: ContractInfoData;

    get invoiceLines(): InvoiceLineData[] {
        return this.contractInfo.invoice_lines;
    }
}
