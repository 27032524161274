<template>
    <el-collapse
        class="jobs-accordion"
        @change="onChange"
        ref="jobsAccordion" accordion>
        <el-collapse-item
            class="jobs-accordion-item"
            v-for="(jobs, type) in jobsByType"
            :key="type" :title="type.toUpperCase()"
            :name="type">
            <job-button v-for="job in jobs" :key="job.id" :job="job" :mode="mode" />
        </el-collapse-item>
    </el-collapse>
</template>

<script lang="ts">
import {Options, prop} from "vue-class-component";
import Vue from "@/extensions/Vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import JobButton from "@/components/controls/JobButton.vue";
import JobData from "@/http/data/job-data";
import {jobContext} from "@/store";
import _ from "lodash";
import {ElCollapse} from "element-plus";

class Props {
    jobs = prop({
        required: true,
        type: Array
    });
    mode = prop({
        required: false,
        type: String,
        default: "type"
    });
}

@Options({
    name: "JobAccordion",
    components: {
        JobButton,
        DefaultLayout
    },
})
export default class JobAccordion extends Vue.with(Props) {
    declare jobs: JobData[];
    declare mode: string;

    mounted(): void {
        this.$events.on("update", () => this.onUpdate());

        this.onUpdate();
    }

    get collapsedJobAccordionItem(): string {
        return jobContext.state.collapsedJobAccordionItem;
    }

    set collapsedJobAccordionItem(value: string) {
        jobContext.mutations.setCollapsedJobAccordionItem(value);
    }

    get jobsByType(): Record<string, JobData[]> {
        return _.chain(this.jobs)
            .orderBy(job => [job.type, job.perform_date, job.unit_id])
            .groupBy(job => job.type)
            .value();
    }

    onChange(collapsedJobAccordionItem: string): void {
        this.collapsedJobAccordionItem = collapsedJobAccordionItem;
    }

    onUpdate(): void {
        const jobsAccordion = this.$refs["jobsAccordion"] as typeof ElCollapse;

        if (!jobsAccordion) return;

        jobsAccordion.setActiveNames(this.collapsedJobAccordionItem);
    }
}
</script>

<style lang="scss" scoped>
.jobs-accordion {
    &:deep {
        .el-collapse-item__content {
            padding-bottom: 0;
        }

        .el-collapse-item__header {
            font-weight: bold;
            padding-left: 0.5rem;
        }
    }
}
</style>